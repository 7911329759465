<template>
  <b-modal ref="modal2" id="modal2" title="Admit Patient" centered size="huge" hide-footer>

    <div class="row">
        <div class="col-md-4">
            <div class="form-group mb-2">
                <div class="col-sm-12">
                    <label class="col-form-label">Patient Number</label>
                    <input type="text" v-model="opd.patient_number" class="form-control" name="patient_number" placeholder="Patient Number" disabled/>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mb-2">
                <div class="col-sm-12">
                    <label class="col-form-label">Concern Doctor</label>
                        <multiselect v-model="$v.typeform.concern_doctor.$model" :options="doctors" :multiple="false"
                            :close-on-select="true" label="name"
                                :clear-on-select="false" :preserve-search="true" placeholder="Please select doctor"
                                    :preselect-first="true" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.concern_doctor.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.concern_doctor.required">Please select doctor</span>
                        </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mb-2">
                <div class="col-sm-12">
                    <label class="col-form-label">Admission Date</label>
        			<div class="form-control" style="background:white">
                        <DatePicker class="date-picker"
                                    v-model="$v.typeform.admission_date.$model"
                                    format="DD-MM-YYYY"
                                    placeholder="Date of Birth"
                                    :disabled-date="disableDatesAfterMax"/>
                    </div>
                        <div v-if="$v.typeform.admission_date.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.admission_date.required">Please Enter Admission Date</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
            <div class="form-group mb-2">
                <div class="col-sm-12">
                    <label class="col-form-label">Select Ward</label>
                        <multiselect v-model="$v.typeform.selected_ward.$model" :options="ward_list" :multiple="false"
                            :close-on-select="true" label="name"
                                :clear-on-select="false" :preserve-search="true" placeholder="Please select ward"
                                    :preselect-first="true" :showLabels="false" @input=getIPDRoomList()></multiselect>
                        <div v-if="$v.typeform.selected_ward.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.selected_ward.required">Please select ward</span>
                        </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mb-2">
                <div class="col-sm-12">
                    <label class="col-form-label">Select Room</label>
                        <multiselect v-model="$v.typeform.selected_room.$model" :options="room_list" :multiple="false"
                            :close-on-select="true" label="name"
                                :clear-on-select="false" :preserve-search="true" placeholder="Please select room"
                                    :preselect-first="true" :showLabels="false" @input=getIPDRoomBedList()></multiselect>
                        <div v-if="$v.typeform.selected_room.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.selected_room.required">Please select room</span>
                        </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mb-2">
                <div class="col-sm-12">
                    <label class="col-form-label">Select Bed Number</label>
                        <multiselect v-model="$v.typeform.selected_room_bed.$model" :options="room_bed_list" :multiple="false"
                            :close-on-select="true" label="bed_name"
                                :clear-on-select="false" :preserve-search="true" placeholder="Please select room bed"
                                    :preselect-first="true" :showLabels="false"
                                    :disabled="room_bed_list.length <= 1"></multiselect>
                        <div v-if="$v.typeform.selected_room_bed.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.selected_room_bed.required">Please select room</span>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group row mb-2">
                <div class="col-sm-12">
                    <label class="col-form-label">Admission Reason</label>
                    <textarea rows="5" type="text" class="form-control" v-model="$v.typeform.admission_reason.$model" name="admission_reason" placeholder="Admission Reason" :class="{ 'is-invalid':  $v.typeform.admission_reason.$error }"/>
                    <div v-if="$v.typeform.admission_reason.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform.admission_reason.required">Please enter admission reason</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row mb-2">
                <div class="col-sm-12">
                    <label class="col-form-label">Notes</label>
                    <textarea rows="5" type="text" class="form-control" v-model="$v.typeform.notes.$model" name="notes" placeholder="Notes" :class="{ 'is-invalid':  $v.typeform.notes.$error }"/>
                    <div v-if="$v.typeform.notes.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform.notes.required">Please enter otes</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4 mb-3">
        <div class="col-md-4"></div>
        <div class="col-md-6">
            <b-button v-if="is_btn_spinner" variant="primary" disabled>
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </b-button>
            <div v-else class="row">
                <button type="button"
                          class="col-md-4 btn btn-outline-secondary me-2 mb-4 mb-lg-0" @click="hide()">Close</button>
                <button type="button"
                  class="col-md-4 btn btn-gradient-primary me-2 mb-4 mb-lg-0" @click="admitPatient()">Admit</button>
            </div>
          </div>
      </div>
  </b-modal>
</template>
<script>
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    components: {
      Multiselect,
      DateRangePicker,
      DatePicker
    },
    data() {
        const now = new Date()
        return {
          moment,
          opd:null,
          typeform: {
            concern_doctor:'',
            selected_room:'',
            selected_room_bed:'',
            selected_wad:'',
            admission_date:now,
            admission_reason:'',
            notes:'',
          },
          doctors:[],
          NOW:now,
          ward_list:[],
          room_list:[],
          room_bed_list:[],
          is_btn_spinner:false,
        }
    },
    validations: {
        typeform: {
            concern_doctor:{required},
            admission_date:{required},
            selected_ward:{required},
            selected_room:{required},
            selected_room_bed:{required},
            admission_reason:{required},
            notes:{},
        }
    },
    mounted(){
    },
    methods: {
        ...mapActions("hospital",["getConcernDoctorListData", "getIPDRoomListData", "getIPDRoomBedListData", "admitPatientData", "getIPDWardListData"]),
        show() {
            this.$refs.modal2.show();
            this.getDoctorList()
            this.getIPDWardList()
        },

        hide() {
            this.$emit('hide', true)
            this.$refs.modal2.hide();
        },

        clearData() {
            this.typeform.concern_doctor = ''
            this.typeform.selected_room = ''
            this.typeform.selected_room_bed = null
            this.typeform.admission_date = this.NOW,
            this.typeform.admission_reason = ''
            this.typeform.notes = ''
            this.doctors = []
            this.room_list = []
            this.room_bed_list = []
            this.is_btn_spinner = false
        },

        receiveData(opddetails) {
            this.opd = opddetails
        },

        getDoctorList(){
            this.doctors = []
            this.typeform.concern_doctor = ''
            var bodyFormData = new FormData();
            -bodyFormData.append('filter_value', '');
            bodyFormData.append('department_id',this.opd.department_id);
            this.getConcernDoctorListData(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.doctors = response.data;
                }
            });
        },

        getIPDWardList(){
            this.ward_list = []
            this.typeform.selected_ward = ''
            var bodyFormData = new FormData();
            bodyFormData.append('search_text', '');
            this.getIPDWardListData(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.ward_list = response.data;
                }
            });
        },

        getIPDRoomList(){
            this.room_list = []
            this.typeform.selected_room = ''
            var bodyFormData = new FormData();
            -bodyFormData.append('search_text', '');
            -bodyFormData.append('occupancy_status', 'Vacant');
            -bodyFormData.append('ward_id', this.typeform.selected_ward.id);
            this.getIPDRoomListData(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.room_list = response.data;
                }
            });
        },

        getIPDRoomBedList(){
            this.room_bed_list = []
            this.typeform.selected_room_bed = ''
            var bodyFormData = new FormData();
            -bodyFormData.append('room_id', this.typeform.selected_room.id);
            this.getIPDRoomBedListData(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.room_bed_list = response.data;
                    if(this.room_bed_list.length == 1) {
                        this.typeform.selected_room_bed = this.room_bed_list[0]
                    }
                }
            });
        },

        admitPatient() {
          if (!this.typeform.concern_doctor) {
            this.$toasted.error("Please select doctor", {duration: 2000,});
            return;
          }
          if (!this.typeform.admission_date) {
            this.$toasted.error("Please select admission date", {duration: 2000,});
            return;
          }
          if (!this.typeform.selected_room) {
            this.$toasted.error("Please select room", {duration: 2000,});
            return;
          }
          if (!this.typeform.selected_room_bed) {
            this.$toasted.error("Please select room bed", {duration: 2000,});
            return;
          }

          this.$v.$touch();
          if (!this.$v.typeform.$invalid) {
              this.is_btn_spinner = true;
              var bodyFormData = new FormData();
              bodyFormData.append('patient_number', this.opd.patient_number);
              bodyFormData.append('room_id', this.typeform.selected_room.id);
              bodyFormData.append('doctor_ids', this.typeform.concern_doctor.id);

              console.log(this.typeform.admission_date)
              if (this.typeform.admission_date) {
                  bodyFormData.append('admission_date', moment(this.typeform.admission_date).format('YYYY-MM-DD'));
              }

              bodyFormData.append('reason_for_admission', this.typeform.admission_reason);
              bodyFormData.append('bed_number', this.typeform.selected_room_bed.bed_number);
              bodyFormData.append('note', this.typeform.notes);
              this.admitPatientData(bodyFormData).then((response) => {
                this.is_btn_spinner = false;
                if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    this.hide()
                } else {
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
        },
         disableDatesAfterMax(date) {
          return date > new Date();
        },

    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
